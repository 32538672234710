import "./app/css/style.css";
import CloudImage from "./app/img/cloud.svg";
import TapImage from "./app/img/tap.png";
import HouseImage from "./app/img/house.png";
import BeerImage from "./app/img/beer.png";
import RankImage from "./app/img/rank.png";
import FooterBgImage from "./app/img/footer-bg.png";
import Nav1Image from "./app/img/nav-1.png";
import Nav2Image from "./app/img/nav-2.png";
import Nav3Image from "./app/img/nav-3.png";
import Nav4Image from "./app/img/nav-4.png";
import InfoImage from "./app/img/info.png";
import CopyImage from "./app/img/copied.png";
import { useEffect, useRef, useState } from "react";
import AxiosInstance from "./helpers/AxiosRequest";
import { copyText, useOnClickOutside } from "./helpers";
import $ from "jquery";
import { Player } from "@lottiefiles/react-lottie-player";
import PunchAnimation from "./lottie_animaitons/Beerboxing_Left_punch.json";
import Lottie from "react-lottie";

function App() {
  const tg = window.Telegram.WebApp;
  const user_id =
    window.Telegram &&
    window.Telegram.WebApp &&
    window.Telegram.WebApp.initDataUnsafe &&
    window.Telegram.WebApp.initDataUnsafe.user &&
    window.Telegram.WebApp.initDataUnsafe.user.id
      ? window.Telegram.WebApp.initDataUnsafe.user.id
      : 5273257384;

  const modalRef = useRef(null);

  const [userDetails, setUserDetails] = useState();
  const [currentBalance, setCurrentBalance] = useState();
  const [isUserFound, setIsUserFound] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [userList, setUserList] = useState();
  const [activeSection, setActiveSection] = useState("landing");
  const [referalUserList, setReferalUserList] = useState();
  const [isShowCopySection, setIsShowCopySection] = useState(false);

  useEffect(() => {
    getUserDetails();
    getTaskList();
    getContestLink();
  }, []);

  useEffect(() => {
    if (activeSection === "ranking") {
      getContestLink();
    } else if (activeSection === "tasks") {
      getTaskList();
    } else if (activeSection === "friends" && userDetails) {
      referalUsers();
    }
  }, [activeSection]);

  useEffect(() => {
    if (userDetails !== undefined && isUserFound === false) {
      setIsUserFound(true);
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails && userDetails.energy < 1000) {
      const interval = setInterval(() => {
        updateUserEnergy();
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [userDetails]);

  useEffect(() => {
    const interval = setInterval(() => {
      saveUserBalance();
    }, 8000);

    return () => clearInterval(interval);
  }, [currentBalance, userDetails]);

  const updateUserEnergy = async () => {
    try {
      if (userDetails) {
        const result = await AxiosInstance.post("/user/updateEnergy", {
          user_id: userDetails.user_id.toString(),
        });
        const { data, status } = result;
        if (status === 200) {
          setUserDetails({
            ...userDetails,
            energy: data.data.energy,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const saveUserBalance = async () => {
    try {
      if (userDetails !== undefined && currentBalance !== userDetails.balance) {
        const result = await AxiosInstance.post("/user/saveBalance", {
          user_id: userDetails.user_id.toString(),
          amount: userDetails.balance - currentBalance,
        });
        const { status } = result;
        if (status === 200) {
          getUserDetails();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getContestLink = async () => {
    try {
      const result = await AxiosInstance.post("/user/ranking", {
        page: 0,
        limit: 100,
      });
      const { data, status } = result;
      if (status === 200) {
        setUserList(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const referalUsers = async () => {
    try {
      const result = await AxiosInstance.post("/user/userReferals", {
        page: 0,
        limit: 100,
        user_id: userDetails.user_id.toString(),
      });
      const { data, status } = result;
      if (status === 200) {
        setReferalUserList(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTaskList = async () => {
    try {
      const result = await AxiosInstance.get("/task/getTask");
      const { data, status } = result;
      if (status === 200) {
        setTaskList(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserDetails = async () => {
    try {
      const result = await AxiosInstance.post("/user/userById", {
        user_id: user_id.toString(),
      });
      const { data, status } = result;
      if (status === 200) {
        setUserDetails(data.data);
        setCurrentBalance(data.data.balance);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleLinkClick = (section) => {
    setActiveSection(section);
  };

  const click = (e) => {
    e.preventDefault();
    plusScore();
    // changeImageAndReturn();
  };

  const plusScore = () => {
    var circleContent = $(".tap");
    tg.HapticFeedback.impactOccurred("medium");
    const plusOne = $("<div class='plus-one'>+1</div>");
    $(".plus-one").remove();

    circleContent.append(plusOne);
    var circleContentWidth = circleContent.width();
    var circleContentHeight = circleContent.height();
    var randX = Math.random() * (circleContentWidth - 40);
    var randY = Math.random() * circleContentHeight;

    if (userDetails.energy <= 0) {
      tg.showAlert("Not Energy");
      return;
    }
    setUserDetails({
      ...userDetails,
      balance: userDetails.balance + 1,
      energy: userDetails.energy - 1,
    });

    plusOne.css({
      position: "absolute",
      transform: "scale(1.2)",
      left: randX + "px",
      top: randY + "px",
      opacity: 0,
      color: "#fff",
      fontWeight: "bold",
      fontSize: "30px",
      textAlign: "center",
      zIndex: 9999,
      display: "block",
      textShadow:
        "rgb(1, 49, 128) 6px 0px 0px, rgb(1, 49, 128) 5.91686px 0.995377px 0px, rgb(1, 49, 128) 5.66974px 1.96317px 0px, rgb(1, 49, 128) 5.2655px 2.87655px 0px, rgb(1, 49, 128) 4.71532px 3.71022px 0px, rgb(1, 49, 128) 4.03447px 4.44106px 0px, rgb(1, 49, 128) 3.24181px 5.04883px 0px, rgb(1, 49, 128) 2.35931px 5.51667px 0px, rgb(1, 49, 128) 1.41143px 5.83163px 0px, rgb(1, 49, 128) 0.424423px 5.98497px 0px, rgb(1, 49, 128) -0.574341px 5.97245px 0px, rgb(1, 49, 128) -1.55719px 5.79441px 0px, rgb(1, 49, 128) -2.49688px 5.45578px 0px, rgb(1, 49, 128) -3.36738px 4.96596px 0px, rgb(1, 49, 128) -4.14455px 4.33852px 0px, rgb(1, 49, 128) -4.80686px 3.59083px 0px, rgb(1, 49, 128) -5.33596px 2.74364px 0px, rgb(1, 49, 128) -5.71718px 1.8204px 0px, rgb(1, 49, 128) -5.93995px 0.84672px 0px, rgb(1, 49, 128) -5.99811px -0.150428px 0px, rgb(1, 49, 128) -5.89004px -1.14341px 0px, rgb(1, 49, 128) -5.61874px -2.1047px 0px, rgb(1, 49, 128) -5.19172px -3.00766px 0px, rgb(1, 49, 128) -4.62082px -3.82727px 0px, rgb(1, 49, 128) -3.92186px -4.54081px 0px, rgb(1, 49, 128) -3.11421px -5.12852px 0px, rgb(1, 49, 128) -2.22026px -5.57409px 0px, rgb(1, 49, 128) -1.26477px -5.86518px 0px, rgb(1, 49, 128) -0.274238px -5.99373px 0px, rgb(1, 49, 128) 0.723898px -5.95617px 0px, rgb(1, 49, 128) 1.70197px -5.75355px 0px, rgb(1, 49, 128) 2.63288px -5.39147px 0px, rgb(1, 49, 128) 3.49082px -4.87998px 0px, rgb(1, 49, 128) 4.25202px -4.23324px 0px, rgb(1, 49, 128) 4.89538px -3.46919px 0px, rgb(1, 49, 128) 5.40307px -2.60899px 0px, rgb(1, 49, 128) 5.76102px -1.67649px 0px, rgb(1, 49, 128) 5.95932px -0.697531px 0px",
    });

    plusOne
      .animate(
        {
          opacity: 1,
          scale: 1.1,
          marginTop: "+=10px",
        },
        500
      )
      .fadeOut(600, function () {
        $(this).remove();
      });
  };

  const handleCompleteTask = async (taskId) => {
    try {
      const result = await AxiosInstance.post("/task/completeTask", {
        user_id: userDetails.user_id.toString(),
        task_id: taskId,
      });
      const { status } = result;
      if (status) {
        getUserDetails();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const changeImageAndReturn = () => {
    // var punchImage = $(".punch");
    // punchImage.attr("src", PunchImage);
    // setTimeout(function () {
    //   punchImage.attr("src", PunchImage);
    // }, 380);
  };

  useOnClickOutside(modalRef, () => setIsShowCopySection(false));
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: PunchAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <>
      {userDetails && (
        <div className="main">
          <section
            className="landing section"
            style={{ display: activeSection === "landing" ? "block" : "none" }}
          >
            <div className="cloud-img">
              <img alt="" src={CloudImage} />
            </div>

            <div className="counter-card">
              <div className="counter">{userDetails.balance}</div>
              <div className="range">
                <div className="">{userDetails.energy}</div>
                <div className="light">1000</div>
              </div>
              <div className="progress">
                <div
                  className="bar"
                  style={{ width: `${userDetails.energy / 10}%` }}
                >
                  <div></div>
                </div>
              </div>
            </div>

            <div className="buttons">
              <div className="task">
                <button
                  type="button"
                  className="active"
                  onClick={() => setActiveSection("tasks")}
                >
                  <span className="icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_15_20234)">
                        <path
                          d="M11 6H20"
                          stroke="#FCFCFC"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11 12H20"
                          stroke="#FCFCFC"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 18H20"
                          stroke="#FCFCFC"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4 16C4 15.4696 4.21071 14.9609 4.58579 14.5858C4.96086 14.2107 5.46957 14 6 14C6.53043 14 7.03914 14.2107 7.41421 14.5858C7.78929 14.9609 8 15.4696 8 16C8 16.591 7.5 17 7 17.5L4 20H8"
                          stroke="#FCFCFC"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6 10V4L4 6"
                          stroke="#FCFCFC"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_15_20234">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Tasks
                </button>
              </div>
              <div className="rank">
                <button
                  type="button"
                  className=""
                  onClick={() => setActiveSection("ranking")}
                >
                  <span className="icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_15_20244)">
                        <path
                          d="M17 2C17.5046 1.99984 17.9906 2.19041 18.3605 2.5335C18.7305 2.87659 18.9572 3.34684 18.995 3.85L19 4V8C19 9.335 18.771 10.386 18.226 11.692L18.069 12.055L17.759 12.756C17.3071 13.7799 17.0523 14.8797 17.008 15.998L17 16.375V20C17.0002 20.5046 16.8096 20.9906 16.4665 21.3605C16.1234 21.7305 15.6532 21.9572 15.15 21.995L15 22H9C8.49542 22.0002 8.00943 21.8096 7.63945 21.4665C7.26947 21.1234 7.04284 20.6532 7.005 20.15L7 20V16.375C7 15.243 6.79 14.125 6.383 13.095L6.241 12.755L5.931 12.056C5.327 10.698 5.048 9.646 5.006 8.358L5 8V4C4.99984 3.49542 5.19041 3.00943 5.5335 2.63945C5.87659 2.26947 6.34684 2.04284 6.85 2.005L7 2H17ZM17 4H7V7H17V4Z"
                          fill="#2F4858"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_15_20244">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Rank
                </button>
              </div>
            </div>

            <div className="tap" onClick={click}>
              <img alt="" src={TapImage} />
            </div>
            {/* <div>
              <Lottie options={defaultOptions} height={400} width={400} />
            </div> */}
          </section>

          <section
            className="friends section"
            style={{ display: activeSection === "friends" ? "block" : "none" }}
          >
            <div className="hut">
              <img alt="" src={HouseImage} />
            </div>

            <div className="hut-card">
              <div className="buttons">
                <div className="ordinary">
                  <button type="button" className="active">
                    Ordinary
                  </button>
                </div>
                <div className="special">
                  <button type="button">Special</button>
                </div>
              </div>

              {/* <!-- <div className="no-data">
                <img alt="" src="img/info.png" /> &nbsp; No friends yet
            </div> --> */}
              {referalUserList && referalUserList.totalCount > 0 ? (
                referalUserList.referalUserData.map((data, index) => (
                  <div className="friends-list">
                    <div className="item">
                      <div className="first">
                        <div className="index">{index + 1}</div>
                        <div className="user">
                          <div className="name">{data.login || "-"}</div>
                          <div className="info">
                            <div className="">
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_15_18609)">
                                  <path
                                    d="M2.5 3.5C2.5 4.03043 2.71071 4.53914 3.08579 4.91421C3.46086 5.28929 3.96957 5.5 4.5 5.5C5.03043 5.5 5.53914 5.28929 5.91421 4.91421C6.28929 4.53914 6.5 4.03043 6.5 3.5C6.5 2.96957 6.28929 2.46086 5.91421 2.08579C5.53914 1.71071 5.03043 1.5 4.5 1.5C3.96957 1.5 3.46086 1.71071 3.08579 2.08579C2.71071 2.46086 2.5 2.96957 2.5 3.5Z"
                                    stroke="#2F4858"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M1.5 10.5V9.5C1.5 8.96957 1.71071 8.46086 2.08579 8.08579C2.46086 7.71071 2.96957 7.5 3.5 7.5H5.5C5.98 7.5 6.42 7.669 6.765 7.9505"
                                    stroke="#2F4858"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M8 1.565C8.43021 1.67515 8.81152 1.92535 9.08382 2.27616C9.35612 2.62696 9.50392 3.05842 9.50392 3.5025C9.50392 3.94659 9.35612 4.37804 9.08382 4.72885C8.81152 5.07965 8.43021 5.32985 8 5.44"
                                    stroke="#2F4858"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M8 9.5H11"
                                    stroke="#2F4858"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M9.5 8V11"
                                    stroke="#2F4858"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_15_18609">
                                    <rect width="12" height="12" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              &nbsp;
                              <span>105</span>
                            </div>
                            <div className="">
                              <span>{data.balance}</span>
                              &nbsp;
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_15_18619)">
                                  <path
                                    d="M8.5 1C8.75229 0.99992 8.99528 1.0952 9.18027 1.26675C9.36526 1.43829 9.47858 1.67342 9.4975 1.925L9.5 2V4C9.5 4.6675 9.3855 5.193 9.113 5.846L9.0345 6.0275L8.8795 6.378C8.65355 6.88993 8.52616 7.43987 8.504 7.999L8.5 8.1875V10C8.50008 10.2523 8.4048 10.4953 8.23325 10.6803C8.06171 10.8653 7.82658 10.9786 7.575 10.9975L7.5 11H4.5C4.24771 11.0001 4.00472 10.9048 3.81973 10.7333C3.63474 10.5617 3.52142 10.3266 3.5025 10.075L3.5 10V8.1875C3.5 7.6215 3.395 7.0625 3.1915 6.5475L3.1205 6.3775L2.9655 6.028C2.6635 5.349 2.524 4.823 2.503 4.179L2.5 4V2C2.49992 1.74771 2.5952 1.50472 2.76675 1.31973C2.93829 1.13474 3.17342 1.02142 3.425 1.0025L3.5 1H8.5ZM8.5 2H3.5V3.5H8.5V2Z"
                                    fill="#2F4858"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_15_18619">
                                    <rect width="12" height="12" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="second">
                        <span>{data.bonus}</span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_15_20244)">
                            <path
                              d="M17 2C17.5046 1.99984 17.9906 2.19041 18.3605 2.5335C18.7305 2.87659 18.9572 3.34684 18.995 3.85L19 4V8C19 9.335 18.771 10.386 18.226 11.692L18.069 12.055L17.759 12.756C17.3071 13.7799 17.0523 14.8797 17.008 15.998L17 16.375V20C17.0002 20.5046 16.8096 20.9906 16.4665 21.3605C16.1234 21.7305 15.6532 21.9572 15.15 21.995L15 22H9C8.49542 22.0002 8.00943 21.8096 7.63945 21.4665C7.26947 21.1234 7.04284 20.6532 7.005 20.15L7 20V16.375C7 15.243 6.79 14.125 6.383 13.095L6.241 12.755L5.931 12.056C5.327 10.698 5.048 9.646 5.006 8.358L5 8V4C4.99984 3.49542 5.19041 3.00943 5.5335 2.63945C5.87659 2.26947 6.34684 2.04284 6.85 2.005L7 2H17ZM17 4H7V7H17V4Z"
                              fill="#2F4858"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_15_20244">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    {index + 1 !== referalUserList.totalCount && (
                      <div className="divider"></div>
                    )}
                  </div>
                ))
              ) : (
                <div className="no-data">
                  <img alt="" src={InfoImage} /> &nbsp; No friends yet
                </div>
              )}
            </div>

            <div className="buttons">
              <div className="refer">
                <button
                  type="button"
                  className="active"
                  onClick={() => {
                    copyText(
                      `${process.env.REACT_APP_TELEGRAM_BOT_LINK}?start=${userDetails.user_id}`
                    );
                    setIsShowCopySection(true);
                  }}
                >
                  <span className="icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_15_18336)">
                        <path
                          d="M12 2L12.324 2.001L12.642 2.005L13.258 2.022L13.557 2.035L14.136 2.069L14.689 2.115C19.474 2.579 21.421 4.526 21.885 9.311L21.931 9.864L21.965 10.443C21.97 10.541 21.975 10.641 21.978 10.742L21.995 11.358L22 12L21.995 12.642L21.978 13.258L21.965 13.557L21.931 14.136L21.885 14.689C21.421 19.474 19.474 21.421 14.689 21.885L14.136 21.931L13.557 21.965C13.459 21.97 13.359 21.975 13.258 21.978L12.642 21.995L12 22L11.358 21.995L10.742 21.978L10.443 21.965L9.864 21.931L9.311 21.885C4.526 21.421 2.579 19.474 2.115 14.689L2.069 14.136L2.035 13.557C2.03014 13.4574 2.0258 13.3577 2.022 13.258L2.005 12.642C2.002 12.432 2 12.218 2 12L2.001 11.676L2.005 11.358L2.022 10.742L2.035 10.443L2.069 9.864L2.115 9.311C2.579 4.526 4.526 2.579 9.311 2.115L9.864 2.069L10.443 2.035C10.541 2.03 10.641 2.025 10.742 2.022L11.358 2.005C11.568 2.002 11.782 2 12 2ZM12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1054 8.48043 11 8.73478 11 9V11H9L8.883 11.007C8.6299 11.0371 8.39785 11.1627 8.23426 11.3582C8.07067 11.5536 7.98789 11.8042 8.00283 12.0586C8.01776 12.313 8.1293 12.5522 8.31463 12.7272C8.49997 12.9021 8.74512 12.9997 9 13H11V15L11.007 15.117C11.0371 15.3701 11.1627 15.6021 11.3582 15.7657C11.5536 15.9293 11.8042 16.0121 12.0586 15.9972C12.313 15.9822 12.5522 15.8707 12.7272 15.6854C12.9021 15.5 12.9997 15.2549 13 15V13H15L15.117 12.993C15.3701 12.9629 15.6021 12.8373 15.7657 12.6418C15.9293 12.4464 16.0121 12.1958 15.9972 11.9414C15.9822 11.687 15.8707 11.4478 15.6854 11.2728C15.5 11.0979 15.2549 11.0003 15 11H13V9L12.993 8.883C12.9643 8.63975 12.8474 8.41547 12.6644 8.25272C12.4813 8.08996 12.2449 8.00003 12 8Z"
                          fill="#FCFCFC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_15_18336">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span className="title">
                    Refer a friend
                    <br />
                    <small>You earn xx with each referral!</small>
                  </span>
                </button>
              </div>
            </div>
          </section>

          <section
            className="tasks section"
            style={{ display: activeSection === "tasks" ? "block" : "none" }}
          >
            <div className="heading">Tasks</div>

            <div className="task-icon">
              <img alt="" src={BeerImage} />
            </div>

            <div className="hut-card">
              {/* <!-- <div className="no-data">
                <img alt="" src="img/info.png" /> &nbsp; No friends yet
            </div> --> */}

              <div className="task-list">
                {taskList.map((task) => (
                  <div className="item">
                    <div className="info">
                      <div className="name">{task.title}</div>
                      <div className="index">
                        <span>+{task.bonus}</span>
                        &nbsp;
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_15_18619)">
                            <path
                              d="M8.5 1C8.75229 0.99992 8.99528 1.0952 9.18027 1.26675C9.36526 1.43829 9.47858 1.67342 9.4975 1.925L9.5 2V4C9.5 4.6675 9.3855 5.193 9.113 5.846L9.0345 6.0275L8.8795 6.378C8.65355 6.88993 8.52616 7.43987 8.504 7.999L8.5 8.1875V10C8.50008 10.2523 8.4048 10.4953 8.23325 10.6803C8.06171 10.8653 7.82658 10.9786 7.575 10.9975L7.5 11H4.5C4.24771 11.0001 4.00472 10.9048 3.81973 10.7333C3.63474 10.5617 3.52142 10.3266 3.5025 10.075L3.5 10V8.1875C3.5 7.6215 3.395 7.0625 3.1915 6.5475L3.1205 6.3775L2.9655 6.028C2.6635 5.349 2.524 4.823 2.503 4.179L2.5 4V2C2.49992 1.74771 2.5952 1.50472 2.76675 1.31973C2.93829 1.13474 3.17342 1.02142 3.425 1.0025L3.5 1H8.5ZM8.5 2H3.5V3.5H8.5V2Z"
                              fill="#2F4858"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_15_18619">
                              <rect width="12" height="12" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    {(userDetails.tasks.length > 0 &&
                      !userDetails.tasks.includes(task.id)) ||
                    userDetails.tasks.length === 0 ? (
                      <div className="button">
                        <button
                          type="button"
                          className=""
                          onClick={() => handleCompleteTask(task.id)}
                          disabled={userDetails.balance < 1000}
                        >
                          Join
                        </button>
                      </div>
                    ) : (
                      <div class="button">
                        <button type="button" class="active">
                          Claimed
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section
            className="ranking section"
            style={{ display: activeSection === "ranking" ? "block" : "none" }}
          >
            <div className="heading">Ranking</div>

            <div className="rank-icon">
              <img alt="" src={RankImage} />
            </div>

            <div className="hut-card">
              <div className="buttons">
                <div className="rank">
                  <button type="button" className="active">
                    Rank
                  </button>
                </div>
                <div className="bonus">
                  <button type="button">Bonus</button>
                </div>
              </div>

              {/* <!-- <div className="no-data">
                <img alt="" src="img/info.png" /> &nbsp; No friends yet
            </div> --> */}

              <div className="ranking-outer">
                {userList &&
                  userList.users.map((data, index) => (
                    <div className="friends-list">
                      <div className="item">
                        <div className="first">
                          <div className="index">{index + 1}</div>
                          <div className="user">
                            <div className="name">{data.login || "-"}</div>
                            <div className="info">
                              {/* <div className="">
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_15_18609)">
                                  <path
                                    d="M2.5 3.5C2.5 4.03043 2.71071 4.53914 3.08579 4.91421C3.46086 5.28929 3.96957 5.5 4.5 5.5C5.03043 5.5 5.53914 5.28929 5.91421 4.91421C6.28929 4.53914 6.5 4.03043 6.5 3.5C6.5 2.96957 6.28929 2.46086 5.91421 2.08579C5.53914 1.71071 5.03043 1.5 4.5 1.5C3.96957 1.5 3.46086 1.71071 3.08579 2.08579C2.71071 2.46086 2.5 2.96957 2.5 3.5Z"
                                    stroke="#2F4858"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M1.5 10.5V9.5C1.5 8.96957 1.71071 8.46086 2.08579 8.08579C2.46086 7.71071 2.96957 7.5 3.5 7.5H5.5C5.98 7.5 6.42 7.669 6.765 7.9505"
                                    stroke="#2F4858"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M8 1.565C8.43021 1.67515 8.81152 1.92535 9.08382 2.27616C9.35612 2.62696 9.50392 3.05842 9.50392 3.5025C9.50392 3.94659 9.35612 4.37804 9.08382 4.72885C8.81152 5.07965 8.43021 5.32985 8 5.44"
                                    stroke="#2F4858"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M8 9.5H11"
                                    stroke="#2F4858"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M9.5 8V11"
                                    stroke="#2F4858"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_15_18609">
                                    <rect width="12" height="12" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              &nbsp;
                              <span>105</span>
                            </div> */}
                              <div className="">
                                <span>{data.balance}</span>
                                &nbsp;
                                <svg
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_15_18619)">
                                    <path
                                      d="M8.5 1C8.75229 0.99992 8.99528 1.0952 9.18027 1.26675C9.36526 1.43829 9.47858 1.67342 9.4975 1.925L9.5 2V4C9.5 4.6675 9.3855 5.193 9.113 5.846L9.0345 6.0275L8.8795 6.378C8.65355 6.88993 8.52616 7.43987 8.504 7.999L8.5 8.1875V10C8.50008 10.2523 8.4048 10.4953 8.23325 10.6803C8.06171 10.8653 7.82658 10.9786 7.575 10.9975L7.5 11H4.5C4.24771 11.0001 4.00472 10.9048 3.81973 10.7333C3.63474 10.5617 3.52142 10.3266 3.5025 10.075L3.5 10V8.1875C3.5 7.6215 3.395 7.0625 3.1915 6.5475L3.1205 6.3775L2.9655 6.028C2.6635 5.349 2.524 4.823 2.503 4.179L2.5 4V2C2.49992 1.74771 2.5952 1.50472 2.76675 1.31973C2.93829 1.13474 3.17342 1.02142 3.425 1.0025L3.5 1H8.5ZM8.5 2H3.5V3.5H8.5V2Z"
                                      fill="#2F4858"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_15_18619">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="second">
                          <span>{data.bonus}</span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_15_20244)">
                              <path
                                d="M17 2C17.5046 1.99984 17.9906 2.19041 18.3605 2.5335C18.7305 2.87659 18.9572 3.34684 18.995 3.85L19 4V8C19 9.335 18.771 10.386 18.226 11.692L18.069 12.055L17.759 12.756C17.3071 13.7799 17.0523 14.8797 17.008 15.998L17 16.375V20C17.0002 20.5046 16.8096 20.9906 16.4665 21.3605C16.1234 21.7305 15.6532 21.9572 15.15 21.995L15 22H9C8.49542 22.0002 8.00943 21.8096 7.63945 21.4665C7.26947 21.1234 7.04284 20.6532 7.005 20.15L7 20V16.375C7 15.243 6.79 14.125 6.383 13.095L6.241 12.755L5.931 12.056C5.327 10.698 5.048 9.646 5.006 8.358L5 8V4C4.99984 3.49542 5.19041 3.00943 5.5335 2.63945C5.87659 2.26947 6.34684 2.04284 6.85 2.005L7 2H17ZM17 4H7V7H17V4Z"
                                fill="#2F4858"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_15_20244">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      {index + 1 !== userList.totalCount && (
                        <div className="divider"></div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </section>

          <footer className="footer">
            <div className="cloud-img">
              <img alt="" src={FooterBgImage} />
            </div>
            <div className="inner">
              <div
                className={`link ${
                  activeSection === "landing" ? "active" : ""
                }`}
                onClick={() => handleLinkClick("landing")}
              >
                <div className="icon">
                  <img alt="" src={Nav1Image} />
                </div>
                <div className="title">Klickzon</div>
              </div>
              <div
                className={`link ${
                  activeSection === "friends" ? "active" : ""
                }`}
                onClick={() => handleLinkClick("friends")}
              >
                <div className="icon">
                  <img alt="" src={Nav2Image} />
                </div>
                <div className="title">Friends</div>
              </div>
              <div
                className={`link ${activeSection === "tasks" ? "active" : ""}`}
                onClick={() => handleLinkClick("tasks")}
              >
                <div className="icon">
                  <img alt="" src={Nav3Image} />
                </div>
                <div className="title">Tasks</div>
              </div>
              <div
                className={`link ${
                  activeSection === "ranking" ? "active" : ""
                }`}
                onClick={() => handleLinkClick("ranking")}
              >
                {" "}
                <div className="icon">
                  <img alt="" src={Nav4Image} />
                </div>
                <div className="title">Ranking</div>
              </div>
            </div>
          </footer>
          {isShowCopySection && (
            <div className="sidebar show" ref={modalRef}>
              <img src={CopyImage} alt="" />
              <div className="copied">
                <span className="text">LINK COPIED</span>
                <span className="back">LINK COPIED</span>
              </div>
              <p>Please share it with your frens’ and earn points</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default App;
